import React, { useState, useEffect } from 'react';
import './App.css';
import { auth, authCredentials, db } from './firebase';
import axios from 'axios';

function App() {

  useEffect(() => {
    let rate = "";
    let src = "";
    let email = "";
    let lang = "";

    let location = window.location.href;
    let params = location.split("rate=");
    if(params.length > 1) {
      rate = params[1][0];
    }

    let srclist = location.split("source=");
    if(srclist.length > 1) {
      src = srclist[1].split('&')[0];
    }

    let langlist = location.split("lang=");
    if(langlist.length > 1) {
      lang = langlist[1].split('&')[0];
    }

    if(src=="wa_delivery") {
      if(lang == "") {
        window.location.replace("https://wa.me/79779272006?text=Здравствуйте!%20Хочу%20заказать%20доставку%20из-за%20рубежа.");
      } else if(lang == "en") {
        window.location.replace("https://wa.me/79779272006?text=Hello!%20I%20would%20like%20to%20order%20delivery%20from%20Europe.");
      }
    }

    let emaillist = location.split("email=");
    if(emaillist.length > 1) {
      email = emaillist[1].split('&')[0];
    }

    if(rate != "" && src != "") {
      axios.get('https://ipgeolocation.abstractapi.com/v1/?api_key=6794f5e1becd4413a6b13345cd9e6cd6').then(response => {
        let date = new Date();

          auth.signInWithEmailAndPassword(authCredentials.email, authCredentials.password).then(() => {
            db.collection("polls").doc(src).collection("answers").doc(response.data.ip_address).set({
              source: src,
              rate: rate,
              timestamp: date.toISOString(),
              cc: response.data.country_code,
              region: response.data.region,
              email: email
            });
          });
      });

      if(src == "brandgroups" && email != "") {
        let polldata = {
          "email": email
        };

        if(lang == "") {
          document.getElementById("greeting").innerHTML = "Мы запомнили!<br/><strong>Приятных покупок<br/>❤️❤️❤️</strong>";
        } else if(lang == "en") {
          document.getElementById("greeting").innerHTML = "We got it!<br/><strong>Happy shopping<br/>❤️❤️❤️</strong>";
        }

        axios.post(
          "https://beta.test.server.spin4spin.com/admin/polls",
          polldata,
          { withCredentials: true }
        ).then(() => {
          
        }).catch(() => {});
      } else if(src="bg_clients") {
        if(lang == "") {
          document.getElementById("greeting").innerHTML = "Мы запомнили!<br/><strong>Приятных покупок<br/>❤️❤️❤️</strong>";
        } else if(lang == "en") {
          document.getElementById("greeting").innerHTML = "We got it!<br/><strong>Happy shopping<br/>❤️❤️❤️</strong>";
        }
      } else if(src="wa_delivery") {
        if(lang == "") {
          document.getElementById("greeting").innerHTML = "Перенаправление...";
        } else if(lang == "en") {
          document.getElementById("greeting").innerHTML = "Redirecting...";
        }
      } else {
        if(lang == "") {
          document.getElementById("greeting").innerHTML = "Благодарим вас за отзыв!<br/><strong>Мы рады, что вы с нами!<br/>❤️❤️❤️</strong>";
        } else if(lang == "en") {
          document.getElementById("greeting").innerHTML = "Thanks for your feedback!<br/><strong>We're glad to provide our services to you!<br/>❤️❤️❤️</strong>";
        }
      }
    }
  }, []);

  return (
    <div className="App">
      <img src="logo-small.png" className="App__logo" alt="logo"/>
      <p className="App__greeting" id="greeting"></p>
      <a href="https://spin4spin.com/catalog?utm_source=polls">
        <div className='App__button_container'>
          <p className='App__button_title'>SHOP NOW</p>
        </div>
      </a>
    </div>
  );
}

export default App;
