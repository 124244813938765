import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';

const firebaseConfig = {
    apiKey: "AIzaSyBHhlTOHilHSvaVS4y-2MQ128WZm4gz83o",
    authDomain: "spinpolls.firebaseapp.com",
    projectId: "spinpolls",
    storageBucket: "spinpolls.appspot.com",
    messagingSenderId: "541186757527",
    appId: "1:541186757527:web:d3bb55e4f713cdc3c86ad2"
};

firebase.initializeApp(firebaseConfig);
export const db = firebase.firestore();
export const auth = firebase.auth();

export const authCredentials = {
    email: "igalkin@spin4spin.com",
    password: "y8vgP4ff8BmeLKzvXJVUW8PY"
};
